var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"box-card"},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('span',{staticClass:"span_name"},[_vm._v(_vm._s(_vm.discount.name))]),(_vm.discount.state == '0')?_c('span',{staticClass:"span_state"},[_vm._v("(已关闭)")]):(_vm.discount.state == '1')?_c('span',{staticClass:"span_state"},[_vm._v("(进行中)")]):(_vm.discount.state == '2')?_c('span',{staticClass:"span_state"},[_vm._v("(已结束)")]):_vm._e()]),_c('el-col',{staticClass:"span_notes",attrs:{"span":24}},[_vm._v(" 活动时间："+_vm._s(_vm._f("formatDate")(_vm.discount.begin_time))+" - "+_vm._s(_vm._f("formatDate")(_vm.discount.end_time))+" ")]),_c('el-col',{staticClass:"span_notes",attrs:{"span":24}},[_vm._v(" 备注："+_vm._s(_vm.discount.remark)+" ")])],1),_c('el-table',{attrs:{"data":_vm.list},on:{"selection-change":_vm.setSelectRows}},[_c('el-table-column',{attrs:{"align":"center","prop":"id","label":"ID"}}),_c('el-table-column',{attrs:{"align":"right","label":"","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.sku_info.imgUrl)?_c('el-image',{staticClass:"commodity_pic",attrs:{"src":row.sku_info.imgUrl}}):_c('el-image',{staticClass:"commodity_pic",attrs:{"src":"#"}})]}}])}),_c('el-table-column',{attrs:{"align":"left","label":"商品","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.sku_info.name))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"sku_info.price","label":"商品价格"}}),_c('el-table-column',{attrs:{"align":"center","prop":"price","label":"商品折扣价"}}),_c('el-table-column',{attrs:{"align":"center","prop":"discount_rate","label":"折扣率（%）"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.discount_rate)+"%")])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleDelete(row)}}},[_vm._v("删除")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }