<template>
  <el-card class="box-card">
    <el-row>
      <el-col :span="24">
        <span class="span_name">{{ discount.name }}</span>
        <span class="span_state" v-if="discount.state == '0'">(已关闭)</span>
        <span class="span_state" v-else-if="discount.state == '1'">(进行中)</span>
        <span class="span_state" v-else-if="discount.state == '2'">(已结束)</span>
      </el-col>
      <el-col class="span_notes" :span="24">
        活动时间：{{ discount.begin_time | formatDate }} - {{ discount.end_time | formatDate }}
      </el-col>
      <el-col class="span_notes" :span="24">
        备注：{{ discount.remark }}
      </el-col>
    </el-row>
    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="id"
          label="ID">
      </el-table-column>
      <el-table-column align="right" label="" show-overflow-tooltip>
        <template #default="{ row }">
          <el-image v-if="row.sku_info.imgUrl" :src="row.sku_info.imgUrl" class="commodity_pic"/>
          <el-image v-else src="#" class="commodity_pic"/>
        </template>
      </el-table-column>
      <el-table-column align="left" label="商品" show-overflow-tooltip>
        <template #default="{ row }">
          <div>{{ row.sku_info.name }}</div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="sku_info.price"
          label="商品价格">
      </el-table-column>
      <el-table-column
          align="center"
          prop="price"
          label="商品折扣价">
      </el-table-column>
      <el-table-column
          align="center"
          prop="discount_rate"
          label="折扣率（%）">
        <template #default="{ row }">
          <span>{{ row.discount_rate }}%</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import {apiDeleteDiscountGoods, apiGetDiscountGoods} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
  },
  components: {},
  props: [],
  data() {
    return {
      imgShow: true,
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        discount_id: this.$route.query.id,
      },
      discount: {},
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要操作当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteDiscountGoods({id: row.id}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$message.error('未选中任何行')
        return false
      }
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetDiscountGoods(this.queryForm).then(res => {
        if (res.code == 200) {
          this.discount = res.data.discount
          this.list = res.data.goods
          // this.count = res.data.count
        }
      })
      this.listLoading = false
    },
  }
}

</script>
<style>
.commodity_pic {
  width: 60px;
  height: 60px;
}

.span_name {
  font-weight: bold;
  font-size: 17px
}

.span_state {
  color: #ff9d00;
  margin-left: 5px;
  font-size: 14px
}

.span_notes {
  font-size: 14px;
  margin-top: 8px
}

.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.el-form-item {
  margin-bottom: 0;
}

.el-dropdown {
  margin-right: 10px;
}

.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
}

.el-row {
  margin-bottom: 15px;
}
</style>
